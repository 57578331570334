<template>
    <div id="mypage" class="bg-gray-relative-100">
      <div class="bg-body">
        <SubHeader title="결제" :link="'/'" :setting="true"></SubHeader>
        <div class="px-3">
            <div class="point_wrap bg-main p-4">
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                        <small class="text-white">보유 캐쉬</small>
                        <div class="d-flex align-items-center text-white">
                        <b class="fs-4 me-1">{{CheckPrice(cash_point)}}</b>원
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-3 mt-4 pb-4">
            <div class="point_wrap bg-main p-4">
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                        <small class="text-white">보유 상품권</small>
                        <div class="d-flex align-items-center text-white">
                        <b class="fs-4 me-1">{{CheckPrice(coupon_point)}}</b>원
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="py-1 pt-3 mt-3">
        <p class="fs-px-12 px-3 text-gray-relative-500">예약 정보</p>
        <ul class="my-2 shadow-200 bg-body">
          <li class="p-3 border-bottom border-gray-relative-100">
            <div class="flex-between-center">
                <span class="fs-px-14">매장명</span>
                <span class="fs-px-14">{{location_name}}</span>
            </div>
          </li>
          <li class="p-3 border-bottom border-gray-relative-100">
            <div class="flex-between-center">
                <span class="fs-px-14">예약일</span>
                <span class="fs-px-14">{{date}}</span>
            </div>
          </li>
          <li class="p-3 border-bottom border-gray-relative-100">
            <div class="flex-between-center">
                <span class="fs-px-14">예약 시간</span>
                <span class="fs-px-14">{{confirm_time}}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="py-1 pt-3">
        <p class="fs-px-12 px-3 text-gray-relative-500">결제 정보</p>
        <ul class="my-2 shadow-200 bg-body">
          <li class="p-3 border-bottom border-gray-relative-100" v-for="(item,index) in payment_item" :key="index">
            <div class="flex-between-center">
                <span class="fs-px-14">{{ item.item_name}}</span>
                <span class="fs-px-14">{{ CheckPrice(item.item_price)}}</span>
            </div>
            
          </li>
          <li class="p-3 border-bottom border-gray-relative-100">
            <div class="flex-between-center">
                <span class="fs-px-14">합계</span>
                <span class="fs-px-14">{{ CheckPrice(payment_price)}}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="py-1 pt-3">
        <p class="fs-px-12 px-3 text-gray-relative-500">결제 방법</p>
        <ul class="my-2 shadow-200 bg-body">
          <li class="p-3 border-bottom border-gray-relative-100">
            <div class="flex-between-center">
                <span class="fs-px-14">결제방법</span>
                <span class="fs-px-14">
                    <select class="form-control thick text-body border-gray-relative-300 bg-transparent fs-6 fw-bold" v-model="payment_type">
                        <option value="">결제방법을 선택해주세요.</option>
                        <option value="C">상품권</option>
                        <option value="P">캐쉬</option>
                        <option value="CP">상품권 + 캐쉬</option>
                    </select>
                </span>
            </div>
          </li>
        </ul>
        <div class="px-3 text-gray-relative-500 lh-sm small">
            <small>보유하고 있는 상품권이 먼저 사용처리가 되고, 잔여금액에 대해서는 캐쉬가 사용됩니다.</small>
        </div>
      </div>
      <div class="py-1 pt-3 px-4">
        <button class="btn btn-success w-100" @click="PaymentConfirm()">결제하기</button>
      </div>
  
      <Loading v-if="loading"/>
    </div>
  </template>
  
  <script>
  const CryptoJS = require("crypto-js");
  const exactMath = require('exact-math');
  import Loading from '@/components/common/Loading.vue'
  import SubHeader from '@/components/common/SubHeader.vue'
  export default {
    components: {
      SubHeader,
      Loading
    },
    data(){
      return{
        cash_point : '',
        coupon_point:'',
        location_name : '',
        loading :false,
        date : '',
        confirm_time : '',
        people : '',
        payment_item : [],
        payment_type : '',
        payment_price : '',
        code: this.$route.params.code,

      }
    },
    mounted(){
      this.GetMemberTotalCouponPrice();
      this.GetMemberTotalCashPrice();
      this.GetPaymentInfo()
    },
    methods: {
      PaymentConfirm(){
        const payment_type = this.payment_type;

        if(payment_type ==''){
            this.$alert("결제타입을 확인해주세요.");
            return false;
        }
        let check_point = 0;
        if(payment_type =='P'){
            check_point = exactMath.sub(this.cash_point,this.payment_price);
        }else if(payment_type =='C'){
            check_point = exactMath.sub(this.coupon_point,this.payment_price);
        }
        
        if(check_point <0){
            this.$alert("결제 금액을 확인해주세요.");
            return false;
        }


        this.$confirm(`결제진행하시겠습니까?`).then((result)=>{
            if( result.is_ok ){
                const code = this.code;
                const payment_type = this.payment_type;
                this.loading = true;
                const body = {code,payment_type};
                const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
                this.$http.post('/front/payment/PaymentConfirm',{req}).then(
                    (res) =>  { 
                        if(res.status == 200){
                            this.loading = false;
                            if(res.data.code =="200"){
                                this.$alert('결제를 완료했습니다.').then(()=>{this.$router.push('/mypage/reservation')});
                            }
                        }else{
                            this.loading = false;
                        }
                    }
                )

                
            }else if( result.is_cancel ){
                this.$alert('취소되었습니다.');
            }
        })
      },
      GetMemberTotalCouponPrice(){
            
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            // this.loading = true;
            this.$http.post('/front/payment/GetMemberTotalCouponPrice',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            this.coupon_point = body.price;
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push({path:'/signin'});
                                }
                            )
                        }
                    }
                }
            );
        },
        GetMemberTotalCashPrice(){
                
                const body = {};
                const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
                // this.loading = true;
                this.$http.post('/front/payment/GetMemberTotalCashPrice',{req}).then(
                    (res) =>  { 
                        if(res.status == 200){
                            this.loading = false;
                            if(res.data.code =="200"){
                                const e_body = res.data.body;
                                const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                                const body = JSON.parse(d_res);
                                this.cash_point = body.price;
                            }else if(res.data.code =="9999"){
                                this.$store.dispatch('SETLOGOUT').then(
                                    ()=>{
                                        this.$router.push({path:'/signin'});
                                    }
                                )
                            }
                        }
                    }
                );
            },
        CheckPrice(price){
            const amount = price*1;
            return amount.toLocaleString()
        },
        GetPaymentInfo(){
            const code = this.code;
            const body = {code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/front/payment/GetPaymentInfo',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            const info = body.info;
                            this.location_name = info.location_name
                            this.date = info.date
                            this.confirm_time = info.confirm_time

                            this.payment_item = body.item;
                            this.payment_price = body.payment_price;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push({path:'/signin'});
                                }
                            )
                        }
                    }
                }
            );   
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
      #mypage {
          min-height: calc(100vh - 70px) !important;
          padding-bottom: 20px;
      }
  </style>

  
<style lang="scss" scoped>
.point_wrap{
    border-radius: 22px;
}
</style>