<template>
  <div class="mh-vh-100 flex-center-center flex-column text-center">
    <h5 class="text-center fw-bold mb-3">
      고객님!<br />
      선물이 도착했습니다.
    </h5>

    <div class="p-3">
      <img src="@/assets/img/gift2.png" alt="" height="100" class="mb-4" />
      <!-- <img src="@/assets/img/gift.png" alt="" height="70" style="filter:hue-rotate(165deg);transform:rotate(45deg)" class="position-absolute"> -->
      <div>
        {{ sender }} 고객님이 보내신<br />골프앤요트 상품권 선물이 도착했습니다.
        <div class="small py-3 text-muted">
          ※ 자세한 선물 내용은 골프앤요트 앱의<br />
          선물함에서 확인이 가능합니다.
        </div>
      </div>
    </div>

    <div class="py-2 w-100">
      <button @click="goUrl" class="btn thick btn-outline-main">
        선물함 바로가기
      </button>
    </div>
    <div class="position-absolute bottom-0 start-0 w-100 p-3">
      <p class="text-muted mb-2">아직 회원이 아니신가요?</p>
      <!-- <button class="btn btn-main thick w-100 border-0" @click="goSignup">
        회원가입하러 가기
      </button> -->
      <button class="btn btn-main thick w-100 border-0 mb-3">
        <a :href="link" class="d-block"> 앱 다운로드 </a>
      </button>
    </div>
  </div>
</template>

<script>
const CryptoJS = require('crypto-js');

export default {
  data() {
    return {
      sender: this.$route.query.sender,
      pp: false,
      off: false,
      timeout_id: null,
      email: '',
      phone: '',
      link: '',
    };
  },
  components: {},
  props: {
    item_info: {
      type: Object,
    },
  },
  mounted() {
    this.$EventBus.$emit('HideNav');
    this.getStoreLink();
  },
  destroyed() {
    this.$EventBus.$emit('ShowNav');
  },
  methods: {
    goUrl() {
      const origin = window.location.origin;
      window.location.href = `${origin}/mypage/history`;
    },
    goSignup() {
      const origin = window.location.origin;
      window.location.href = `${origin}/signup`;
    },
    getStoreLink() {
      const userAgent = navigator.userAgent;

      if (userAgent.match(/Android/i)) {
        this.link = process.env.VUE_APP_PLAY_STORE;
      } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
        this.link = process.env.VUE_APP_APP_STORE;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  word-break: keep-all;
}
</style>
