<template>
    <div class="p-3 bg-gray-relative-100 mh-vh-100-nav">
        <div class="receipt">
            <div class="bg-body p-3 shadow-200 rounded-3 pb-4">
                
                <div class="py-4 text-center">
                    <h4 class="fw-bold">상품권 매출전표</h4>
                    <p class="small text-gray-relative-600 lh-sm mt-2">{{payment_code}}</p>
                </div>

                <div>
                    <div class="border border-gray-relative-700"></div>
                    <h5 class="py-2">예약자 정보</h5>
                    <div class="cutting_line border-gray-relative-500 pb-4"></div>
                </div>
                <div>
                    <ul class="small">
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">이름</p>
                            <p class="w-100">{{user_name}}</p>
                        </li>
                    </ul>
                </div>

                

                <div class="mt-5">
                    <div class="border border-gray-relative-700"></div>
                    <h5 class="py-2">예약정보</h5>
                    <div class="cutting_line border-gray-relative-500 pb-4"></div>
                </div>
                <div>
                    <ul class="small">
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">상태</p>
                            <p class="w-100 flex-start-center">
                                <span>{{CheckStatus(r_status)}}</span>
                                <span class="btn btn-sm btn-danger py-0 ms-2" @click="ReservationCancel()" v-if="CheckCancelAble() =='Y'">예약취소</span>
                            </p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">골프장명</p>
                            <p class="w-100">{{location_name}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">예약일자</p>
                            <p class="w-100">{{reservation_date}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">예약시간</p>
                            <p class="w-100">{{confirm_time}}</p>
                        </li>
                    </ul>
                </div>



                <div class="mt-5">
                    <div class="border border-gray-relative-700"></div>
                    <h5 class="py-2">결제정보</h5>
                    <div class="cutting_line border-gray-relative-500 pb-4"></div>
                </div>
                <div class="">
                    <ul class="small">
                        <!-- <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">구매상품</p>
                            <div>
                                <p class="w-100" v-for="(item,index) in item_list" :key="index">{{ item.name }}</p>
                            </div>
                            
                        </li> -->
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">결제수단</p>
                            <p class="w-100">{{CheckPaymentType(payment_type)}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">거래상태</p>
                            <p class="w-100" v-if="status=='C'">취소</p>
                            <p class="w-100" v-if="status=='Y'">승인</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">거래일시</p>
                            <p class="w-100">{{create_time}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2" v-if="status == 'C'">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">취소일시</p>
                            <p class="w-100 text-danger">{{update_time}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">승인금액</p>
                            <p class="w-100">{{(total).toLocaleString()}}</p>
                        </li>
                        <li class="d-flex justify-content-between pt-2 px-2 pb-2 bg-gray-relative-100" v-if="total !=''">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">┕ 과세금액</p>
                            <p class="w-100">{{(total*0.9).toLocaleString()}}</p>
                        </li>
                        <li class="d-flex justify-content-between pb-2 px-2 mb-2 bg-gray-relative-100" v-if="total !=''">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">┕ 부가세</p>
                            <p class="w-100">{{(total*0.1).toLocaleString()}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2" v-if="payment_type=='C'">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">상품권 권종</p>
                            <div class="w-100" ><div v-for="(p_item,p_index) in payment_name.split('||')" :key="p_index">{{p_item}}</div></div>
                        </li>
                        <li class="d-flex justify-content-between mb-2" v-if="payment_type=='C'">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">상품권 핀번호</p>
                            <div class="w-100" ><div v-for="(p_item,p_index) in payment_target.split('||')" :key="p_index">{{p_item}}</div></div>
                        </li>
                        <li class="d-flex justify-content-between mb-2" v-if="payment_type=='P'">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">승인코드</p>
                            <p class="w-100">{{payment_target}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2" v-if="payment_type=='CP'">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">결제정보</p>
                            <div class="w-100" ><div v-for="(p_item,p_index) in payment_name.split('||')" :key="p_index">{{p_item}}</div></div>
                        </li>

                        <!-- <li class="d-flex justify-content-between mb-4">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">고객전화번호</p>
                            <p class="w-100">{{user_phone}}</p>
                        </li> -->
                    </ul>
                </div>

                
                    
                <div class="mt-5">
                    <div class="border border-gray-relative-700"></div>
                    <h5 class="py-2">공급자 정보</h5>
                    <div class="cutting_line border-gray-relative-500 pb-4"></div>
                </div>
                <div>
                    <ul class="small">
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">상호</p>
                            <p class="w-100">{{shop_name}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">대표자명</p>
                            <p class="w-100">{{shop_owner}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">전화번호</p>
                            <p class="w-100">{{shop_tel}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">사업자번호</p>
                            <p class="w-100">{{business_number}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3 w-px-80">주소</p>
                            <p class="w-100">{{shop_address}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex-between-center mt-4">
                <router-link to="/mypage/reservation" class="btn thick btn-main w-100 text-white">
                    확인
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");
export default {
    data(){
        return{
            payment_code: this.$route.params.code,
            item_list: [],
            shop_name : '',
            shop_owner:'',
            shop_tel:'',
            business_number : '',
            shop_address : '',
            user_name : '',
            user_phone : '',
            total : '',
            payment_type : '',
            create_time:'',
            update_time : '',
            status : '',
            payment_target : '',
            cancel_able:'',
            r_status : '',
            location_name : '',
            reservation_date: '',
            confirm_time: '',
        }
    },
    methods:{
        CheckCancelAble(){
            if(this.cancel_able =='Y'){
                if(this.r_status !='C'){
                    return 'Y'
                }else{
                    return 'N'
                }
            }else{
                return 'N'
            }
        },
        CheckStatus(status){
            if(status =='Y'){
                return "예약 완료"
            }else if(status =='C'){
                return "예약 취소"
            }
        },
        CheckPaymentType(type){
            if(type =='P'){
                return "캐쉬"
            }else if(type =='C'){
                return "지류상품권"
            }else if(type =='CP'){
                return "상품권 + 캐쉬"
            }

        },
        ReservationCancel(){
            const payment_code = this.payment_code;
            
            const body = {payment_code};

            this.$confirm("예약을 취소하시겠습니까?").then(
                ( result )=>{
                    if( result.is_ok ){
                        const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
                        this.loading = true;
                        this.$http.post('/front/payment/ReservationCancel',{req}).then(
                            (res) =>  { 
                                if(res.status == 200){
                                    this.loading = false;
                                    if(res.data.code =="200"){
                                        this.$alert("취소하였습니다.").then(
                                            ()=>{
                                                this.$router.go(-1);
                                            }
                                        )
                                    }else if(res.data.code=='9999'){
                                        this.$store.dispatch('SETLOGOUT').then(
                                            ()=>{
                                                window.location.href="/signin";
                                            }
                                        )
                                    }
                                }
                            }
                        );
                    }
                }
            )
            
        },
        GetReceipt(){
            const payment_code = this.payment_code;
            
            const body = {payment_code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/payment/GetReceipt',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.item_list = body.item_list;
                            
                            this.user_name = body.info.user_name;
                            this.user_phone = body.info.user_phone;



                            this.payment_type = body.info.payment_type;
                            this.payment_target = body.info.payment_target;
                            this.payment_name = body.info.payment_name;

                            this.total = body.info.total*1;

                            this.shop_name = body.info.shop_name;
                            this.shop_owner = body.info.shop_owner;
                            this.shop_tel= body.info.shop_tel;
                            this.business_number = body.info.business_number;
                            this.shop_address= body.info.shop_address;
                            this.status = body.info.status;
                            this.cancel_able = body.info.cancel_able;
                            this.create_time = body.info.create_time;
                            this.update_time = body.info.update_time;
                            this.reservation_date = body.reservation_info.date;
                            this.location_name = body.reservation_info.location_name;
                            this.confirm_time = body.reservation_info.confirm_time;
                            this.r_status = body.reservation_info.status;
                            this.cancel_able = body.reservation_info.cancel_able;
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            );
        },
        
    },
    mounted(){
        this.GetReceipt()
        // this.PurchasedItem();
    }
}
</script>
<style scoped>
.cutting_line{
    border-top: 1px dashed;
}
</style>